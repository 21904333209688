import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './component/scrollToTop/ScrollToTop';
import 'bootstrap/dist/css/bootstrap.min.css';
import Accueil from './pages/Accueil';
import Services from './pages/Services';
import ServiceDetails from './pages/ServiceDetails';
import Portfolio from './pages/Portfolio';
import PortfolioDetails from './pages/PortfolioDetails';
import Apropos from './pages/Apropos';
import Contact from './pages/Contact';
import ErrorPage from './pages/404';
import PolitiqueDeConfidentialite from './pages/politiqueDeConfidentialite';
import ConditionsdDutilisation from './pages/ConditionsdD-utilisation';
import './assets/scss/app.scss';


const App = () => {
	return (
		<Router>
			<ScrollToTop>
				<Routes>
					<Route path={process.env.PUBLIC_URL + "/"} element={<Accueil />} />

					{/* Service */}
					<Route path={process.env.PUBLIC_URL + "/services"} element={<Services />} />
					<Route path={process.env.PUBLIC_URL + "/service-details/:slug"} element={<ServiceDetails />} />

					{/* Project  */}
					<Route path={process.env.PUBLIC_URL + "/portfolio"} element={<Portfolio />} />
					<Route path={process.env.PUBLIC_URL + "/portfolio-details/:slug"} element={<PortfolioDetails />} />

					{/* Pages  */}
					<Route path={process.env.PUBLIC_URL + "/a-propos"} element={<Apropos />} />
					<Route path={process.env.PUBLIC_URL + "/contact"} element={<Contact />} />
					<Route path={process.env.PUBLIC_URL + "/404"} element={<ErrorPage />} />
					<Route path={process.env.PUBLIC_URL + "/politique-de-confidentialite"} element={<PolitiqueDeConfidentialite/>} />
					<Route path={process.env.PUBLIC_URL + "/conditions-d-utilisation"} element={<ConditionsdDutilisation />} />
					<Route path={process.env.PUBLIC_URL + "*"} element={<ErrorPage />} />

				</Routes>
			</ScrollToTop>
		</Router>
	)
}




export default App;
