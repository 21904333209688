import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';


const ConditionsdDutilisation = () => {

    return (
        <>
            <SEO title="Conditions d'utilisation" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                title="Conditions d'utilisation"
                page="Conditions d'utilisation"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                <div className="section-title">
                                    <h5 className="title mb--20">Date de dernière mise à jour : Juin 2024</h5>
                                </div>
                                <p className="mb--20">Bienvenue chez <strong>NZIM GROUP !</strong> Nous sommes ravis de vous avoir parmi nous. Avant de commencer à utiliser nos services digitaux, nous vous demandons de prendre connaissance de ces conditions d'utilisation. En accédant à notre site web et en utilisant nos services, vous acceptez de vous conformer à ces conditions. Si vous avez des questions ou des préoccupations, n'hésitez pas à nous contacter à <a href="mailto:support@nzimgroup.com">support@nzimgroup.com.</a></p>
                                <h4>1. Acceptation des Conditions</h4>
                                <p className="mb--20">En utilisant notre site web et nos services, vous reconnaissez avoir lu, compris et accepté nos conditions. Nous pouvons les mettre à jour de temps en temps, alors assurez-vous de les consulter régulièrement pour rester informé des changements.
                                </p>
                                <h4>2. Utilisation des Services</h4>
                                <ul className="mb--20">
                                    <li><strong>Éligibilité :</strong> Vous devez avoir au moins 18 ans ou avoir l'autorisation de vos parents ou tuteurs pour utiliser nos services.</li>
                                    <li><strong>Compte Utilisateur :</strong>  Pour accéder à certaines fonctionnalités, vous devrez peut-être créer un compte en fournissant des informations exactes et à jour. Veillez à garder votre mot de passe secret et à nous signaler toute activité suspecte sur votre compte.</li>
                                    <li><strong>Utilisation Autorisée :</strong> Utilisez nos services de manière légale et respectueuse. Toute utilisation abusive, frauduleuse ou illégale est interdite.</li>
                                </ul>
                                <h4>3. Contenu et Propriété Intellectuelle</h4>
                                <ul className="mb--20">
                                    <li><strong>Propriété Intellectuelle :</strong> Tout le contenu sur notre site web (textes, images, logos, etc.) nous appartient ou appartient à nos partenaires. Merci de respecter nos droits d'auteur.</li>
                                    <li><strong>Utilisation du Contenu :</strong>   Vous pouvez utiliser notre contenu pour un usage personnel, mais pas pour des fins commerciales sans notre permission.</li>
                                    </ul>
                                <h4>4. Protection des Données</h4>
                                <p className="mb--20">Nous prenons la confidentialité très au sérieux. Notre politique de confidentialité explique comment nous collectons et utilisons vos données personnelles. En utilisant nos services, vous acceptez nos pratiques de confidentialité.</p>
                                <h4>5. Limitation de Responsabilité</h4>
                                <ul className="mb--20">
                                    <li><strong>Disponibilité des Services : </strong> Nous faisons de notre mieux pour que nos services soient toujours disponibles, mais il peut y avoir des interruptions. Nous nous réservons le droit de suspendre ou d'arrêter nos services à tout moment, sans préavis.</li>
                                    <li><strong>Exclusion de Garantie :</strong>  Nos services sont fournis "tels quels". Nous ne pouvons garantir qu'ils seront toujours parfaits ou sans erreur, mais nous faisons de notre mieux pour vous offrir la meilleure expérience possible.</li>
                                </ul>
                                <h4>6. Indemnisation</h4>
                                <p className="mb--20">Si quelqu'un nous poursuit en raison de votre utilisation de nos services ou de votre violation de ces conditions, vous acceptez de nous indemniser pour les coûts et les dommages.</p>
                                <h4> 7. Droit Applicable</h4>
                                <p className="mb--20">Ces conditions sont régies par les lois de votre juridiction. En cas de litige, ce seront les tribunaux locaux qui auront compétence.</p>
                                <h4>8. Contact</h4>
                                <p className="mb--20">Si vous avez des questions ou des préoccupations concernant ces conditions, contactez-nous à <a href="mailto:support@nzimgroup.com">support@nzimgroup.com</a>. Nous sommes là pour vous aider.</p>
                                <p className="mb--20">rci de faire partie de la communauté <strong>NZIM GROUP</strong>. Nous espérons que vous apprécierez nos services !</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <CtaLayoutOne />
            <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default ConditionsdDutilisation;