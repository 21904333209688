import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';


const politiqueDeConfidentialite = () => {

    return (
        <>
            <SEO title="Politique de confidentialité" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                title="Politique de confidentialité"
                page="Politique de confidentialité"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                <div className="section-title">
                                    <h5 className="title">Date de dernière mise à jour : Juin 2024</h5>
                                </div>
                                <p className="mb--20">Chez <strong>NZIM GROUP</strong>, nous respectons votre vie privée et nous nous engageons à protéger vos informations personnelles. Voici comment nous collectons, utilisons et sécurisons vos données. Si vous avez des questions, n'hésitez pas à nous contacter à <a href="mailto:support@nzimgroup.com">support@nzimgroup.com</a>. 
                                </p>
                                <h4>1. Collecte des Données Personnelles</h4>
                                <p className="mb--20">Nous collectons les informations suivantes :</p>
                                <ul className="mb--20">
                                    <li>Nom</li>
                                    <li>Adresse e-mail</li>
                                    <li>Numéro de téléphone</li>
                                </ul>
                                <p className="mb--20">Ces informations sont recueillies via :</p>
                                <ul className="mb--20">
                                    <li><strong>Formulaires en ligne :</strong>  lorsque vous remplissez un formulaire sur notre site.</li>
                                    <li><strong>Cookies :</strong> lorsque vous naviguez sur notre site web pour améliorer votre expérience.</li>
                                </ul>
                                <h4>2. Utilisation des Données Personnelles</h4>
                                <p className="mb--20">Nous utilisons vos informations pour :</p>
                                <ul className="mb--20">
                                    <li><strong>Marketing :</strong> vous envoyer des offres et des nouvelles sur nos services.</li>
                                    <li><strong>Communication avec les clients :</strong> répondre à vos questions et vous offrir du support.</li>
                                </ul>
                                <h4>3. Partage des Données</h4>
                                <p className="mb--20">Nous ne partageons pas vos informations personnelles avec des tiers, sauf si c'est requis par la loi ou avec votre consentement.</p>
                                <h4>4. Sécurité des Données</h4>
                                <p className="mb--20">Nous prenons la sécurité de vos données très au sérieux. Nous utilisons diverses mesures techniques et organisationnelles pour protéger vos informations contre tout accès non autorisé.</p>
                                <h4>5. Vos Droits</h4>
                                <ul className="mb--20">
                                    <li><strong>Accéder</strong> à vos données personnelles que nous avons.</li>
                                    <li><strong>Modifier</strong> vos données pour les tenir à jour.</li>
                                    <li><strong>Supprimer</strong> Supprimer vos données de nos dossiers.</li>   
                                </ul>
                                
                                <p className="mb--20">Pour exercer ces droits, contactez-nous à <a href="mailto:support@nzimgroup.com">support@nzimgroup.com</a></p>
                                <h4>6. Contact</h4>
                                <p className="mb--20">Si vous avez des questions ou des préoccupations concernant notre politique de confidentialité ou nos pratiques, veuillez nous écrire à <a href="mailto:support@nzimgroup.com">support@nzimgroup.com</a>. Nous sommes là pour vous aider.</p>
                                <h4>7. Modifications de la Politique de Confidentialité</h4>
                                <p className="mb--20">Nous pouvons mettre à jour cette politique de confidentialité de temps en temps. Nous vous informerons de tout changement important. Nous vous encourageons à consulter cette page régulièrement pour rester informé.</p>
                                <p className="mb--20">Merci de faire confiance à NZIM GROUP. Votre vie privée est notre priorité, et nous faisons tout notre possible pour la protéger.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CtaLayoutOne />
            <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default politiqueDeConfidentialite;