import React from 'react';
// import { Link } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas'

import { FaFacebookF, FaYoutube, FaLinkedin, FaInstagram, FaTiktok } from "react-icons/fa";
import { FaXTwitter, FaThreads } from "react-icons/fa6";
import {  FaPhone } from "react-icons/fa";
import { Link } from 'react-router-dom';


const OffcanvasMenu = ({ offcanvasShow, offcanvasHide }) => {
    return (
        <Offcanvas show={offcanvasShow} onHide={offcanvasHide} placement="end" className="header-offcanvasmenu">
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body>
                <form action="#" className="side-nav-search-form">
                    <div className="">
                        <h4>Nos services</h4>
                        <div className="cs_underline"></div>

                        {/* <button className="side-nav-search-btn"><i className="fas fa-search"></i></button> */}
                    </div>
                </form>
                <div className="row ">
                    <div className="col-lg-5 col-xl-6">
                        <ul className="main-navigation list-unstyled">
                            <li><Link to={process.env.PUBLIC_URL + "/services#section1"}>Conception Digital</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/services#section2"}>Disign Graphique</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/services#section3"}>Domotique</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/services#section4"}>Événementiel</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/services#section5"}>Service Informatique</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/services#section6"}>Travaux de Dépannage</Link></li>
                        </ul>
                    </div>
                    <div className="col-lg-7 col-xl-6">
                        <div className="contact-info-wrap">
                            <div className="contact-inner">
                                {/* <address className="address">
                                    <span className="title">Contact Information</span>
                                    <p>Theodore Lowe, Ap #867-859 <br /> Sit Rd, Azusa New York</p>
                                </address> */}
                                <address className="address">
                                    <span className="title">Nous sommes disponibles 24h/24 et 7j/7. Appelle maintenant.</span>
                                    <a href="tel:+243832224902" className="tel"><FaPhone /> +243 832 224 902</a>

                                </address>
                            </div>
                            <div className="contact-inner">
                                <h5 className="title">Retrouvez-nous ici</h5>
                                <div className="contact-social-share">
                                    <ul className="social-share list-unstyled">
                                        <li><a href="https://www.facebook.com/nzimgroup" target="blank" rel="noopener noreferrer"><FaFacebookF /></a></li>
                                        <li><a href="https://x.com/NzimGroup" target="blank" rel="noopener noreferrer"><FaXTwitter /></a></li>
                                        <li><a href="https://youtube.com/@nzimgroup?feature=shared" target="blank" rel="noopener noreferrer"><FaYoutube /></a></li>
                                        <li><a href="https://www.linkedin.com/company/nzim-group/" target="blank" rel="noopener noreferrer"><FaLinkedin /></a></li>
                                        <li><a href="https://www.instagram.com/nzim_group?igsh=MXFya3JkYmRzcGdqOA%3D%3D&utm_source=qr" target="blank" rel="noopener noreferrer"><FaInstagram /></a></li>
                                        <li><a href="https://www.threads.net/@nzim_group" target="blank" rel="noopener noreferrer"><FaThreads /></a></li>
                                        <li><a href="https://www.tiktok.com/@nzim_group?_t=8o2kpbqTHHh&_r=1" target="blank" rel="noopener noreferrer"><FaTiktok /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default OffcanvasMenu;
