import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import ProjectPropTwo from './itemProp/ProjectPropTwo';
import ProjectData from "../../data/project/ProjectData.json";
import { Link } from 'react-router-dom';

const portfolioData = ProjectData;
const portfolioFouth = portfolioData.length - 4
const data = portfolioData.slice(portfolioFouth)


const ProjectFour = () => {
    const tabData = []

    for (let i = 0; i < 4; i++) {
        tabData.push(data[i])

    }
    tabData.reverse()
    
 
   

    return (
        <div className="section section-padding-equal bg-color-dark">
            <div className="container">
                <SectionTitle
                    subtitle="Portfolio"
                     
                    textAlignment="heading-light-left mb--90"
                    textColor=""
                />
                <div className="project-add-banner">
                    <div className="content">
                        <h3 className="title">Explorez des projets réalisés avec passion et expertise.</h3>
                        <span className="subtitle ">Découvrez comment nous transformons vos idées en réalité grâce à des solutions créatives et personnalisées.</span>
                    </div>
                    <div className="thumbnail">
                        <img src={process.env.PUBLIC_URL + "/images/project/mockPortfolio.png"} alt="Mockup" />
                    </div>
                </div>

                <div className="row row-45">
                    {tabData.map((data) => (
                        <div className="col-md-6" key={data.id}>
                            <ProjectPropTwo projectStyle="project-style-2" portfolio={data} />
                        </div>
                    ))}

                </div>
                <div className="more-project-btn">
                    <Link to={process.env.PUBLIC_URL + "/portfolio"} className="axil-btn btn-fill-white">Découvrir plus de projets</Link>
                </div>
            </div>
        </div>
    )
}

export default ProjectFour;