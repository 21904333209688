import React from 'react';
import { Link } from 'react-router-dom';



const VideoPub = () => {


    return (
        <>
            <div className="section section-padding-equal">
                <div className="container  ">
                    <div className="aboutExpert">
                        <div className="thumbnail">
                            <iframe className='vidImg' src="https://www.youtube.com/embed/FN58k4weUCM" title="Save The Date" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
                <div className='btnCenter'>
                    <Link to={process.env.PUBLIC_URL + "/service-details/conception-des-invitations"} className="axil-btn btn-fill-primary btn-large">En savoir plus</Link>
                </div>
            </div>
        </>
    )
}


export default VideoPub;

