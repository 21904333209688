import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ProjectFive from '../component/project/ProjectFive';
// import UpdateMetaTags from '../common/UpdateMetaTags';


const Portfolio = () => {

    return (
        <>
            <SEO title="Portfolio" />
            {/* <UpdateMetaTags
                title="Portfolio"
                description="Découvrez comment nous transformons vos idées en réalité grâce à des solutions créatives et personnalisées."
                imageUrl={`${window.location.origin}/images/banner/portfolioBan.png`}
            /> */}
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerOne
                    title="Nos projets"
                    paragraph="Découvrez comment nous transformons vos idées en réalité grâce à des solutions créatives et personnalisées."
                    styleClass=""
                    mainThumb="/images/banner/portfolioBan.png"
                />
                <ProjectFive />
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default Portfolio;