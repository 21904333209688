import React, { useContext } from 'react';
import { MyContext } from '../../utils/MyContext';
import Lottie from "lottie-react";
import chat from "../../assets/lotie/chat.json"
import msg2 from "../../assets/lotie/msg2.json"
import msg3 from "../../assets/lotie/msg3.json"
import ModalOne from '../modal/ModalOne'
// import { Link } from 'react-router-dom';


const CtaLayoutOne = () => {
    const { showModal } = useContext(MyContext);


    return (
        <>
            <ModalOne />

            <div className="section call-to-action-area">
                <div className="container">
                    <div className="call-to-action">
                        <div className="section-heading heading-light">
                            <h2 className="title">Vous avez un projet ?</h2>
                            <span onClick={showModal} className="axil-btn btn-large btn-fill-white">
                                Écrivez-nous
                            </span>
                        </div>
                    </div>
                </div>
                <ul className="list-unstyled shape-group-9">
                    <li className="shape shape-2"><Lottie className='msg3'  animationData={msg3} /> </li>
                    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-13.png"} alt="Comments" /></li>
                    <li className="shape shape-4"><Lottie className='msg2'  animationData={msg2} /> </li>
                    <li className="shape shape-5"><img src={process.env.PUBLIC_URL + "/images/others/bubble-16.png"} alt="Comments" /></li>
                    <li className="shape shape-6"><Lottie className='lotyContact'  animationData={chat} /> </li>
                    <li className="shape shape-7"><img src={process.env.PUBLIC_URL + "/images/others/bubble-16.png"} alt="Comments" /></li>
                </ul>
            </div>
        </>

    )

}

export default CtaLayoutOne;