import React, { useContext, useEffect, useRef, useState, useCallback } from 'react';
import FormTwo from '../contact/FormTwo';
//import { GiCancel } from "react-icons/gi";
import { MyContext } from '../../utils/MyContext';
import Lottie from "lottie-react";
import close from "../../assets/lotie/close.json";


const Modal = () => {
    const modalRef = useRef();
    const { closeModal, isOpen } = useContext(MyContext);
    const [animate, setAnimate] = useState(false);

    const handleClose = useCallback(() => {
        setAnimate(false); 
        setTimeout(() => {
            closeModal(); 
        }, 500); 
    }, [closeModal]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                handleClose();
            }
        };

        if (isOpen) {
            setAnimate(true); 
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            setAnimate(false);
            setTimeout(() => {
                closeModal(); 
            }, 500);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, handleClose, closeModal]);

    if (!isOpen && !animate) return null; 

    return (
        <div
            className={`modal ${animate ? 'fade-in' : 'fade-out'} show cs_center`}
            style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
            role="dialog"
            aria-modal="true"
        >
            <div className="cs_modal_width" ref={modalRef}>
                <div className="modal-body h-100 d-flex justify-content-center">
                    <div className="cs_padding col-xl-4">
                        <div className="contact-form-box shadow-box">
                            <div className='d-flex justify-content-between align-baseline'>
                                <h3 className="title">Écrivez-nous</h3>
                                <button className='cs_bg_btn' onClick={handleClose}>
                                <Lottie style={{ width: '45%', height: '45%' }} animationData={close} className='cs_hover'  />
                                </button>
                            </div>
                            <FormTwo formModal={true} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
