import React, { useContext} from 'react';
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { FaMessage } from "react-icons/fa6";
import { MyContext } from '../../utils/MyContext';




const AboutFour = () => {
    const { showModal } = useContext(MyContext);
    const downloadFile = () => {
        const link = document.createElement('a');
        link.href = `${process.env.PUBLIC_URL}/pdf/profil.pdf`;
        link.download = 'profil.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    return (
        <div className="section section-padding case-study-featured-area">
            <div className="container">
                <div className="row">
                    <div className="col-xl-7 col-lg-6">
                        <div className="case-study-featured-thumb text-start">
                            <img className='rounded-5 ' src={process.env.PUBLIC_URL + "/images/about/BanNzim2.jpg"} alt="travel" />
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6">
                        <div className="case-study-featured">
                            <div className="section-heading heading-left">
                                <span className="subtitle">À propos</span>
                                <h2 className="title">Qui sommes-nous ?</h2>
                                <p>Nous sommes une entreprise multiservice innovante, dédiée à vous offrir la possibilité de bénéficier pleinement des avancées technologiques dans toutes les sphères de votre quotidien. </p>
                                <p>Que ce soit pour améliorer votre productivité au travail ou simplifier votre vie à la maison, nous mettons à votre disposition une gamme complète de solutions adaptées à vos besoins.</p>

                                <div className='d-flex gap-4 w-100' >
                                    <div onClick={showModal} className="btn-fill-primary  cs_btn_pdf">
                                        <FaMessage />
                                        <span>Écrivez-nous</span>
                                    </div>
                                    <button className="btn-fill-primary  cs_btn_pdf" onClick={downloadFile}>
                                        <BsFileEarmarkPdfFill />
                                        <span>Télécharger le profil</span>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>

    )
}

export default AboutFour;