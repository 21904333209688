import React, { createContext, useState, useEffect, useRef } from 'react';


const MyContext = createContext();

const MyProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => {
    setIsOpen(false);
  }
  const showModal = () => {
    setIsOpen(true);
  }
  const modalRef = useRef(null);


  useEffect(() => {
    if (isOpen && modalRef.current) {
      modalRef.current.focus();
    }

    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        closeModal();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);



  const switchColor = () => {
    document.querySelector('body').classList.toggle("active-dark-mode");
  }




  useEffect(() => {
    const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');
    if (darkThemeMq.matches) {
      switchColor();
    }

    const handleChange = (e) => {
      if (e.matches) {
        switchColor();
      }
    };

    darkThemeMq.addEventListener('change', handleChange);

    return () => darkThemeMq.removeEventListener('change', handleChange);
  }, []);

  return (
    <MyContext.Provider value={{ showModal, closeModal, isOpen, switchColor }}>
      {children}
    </MyContext.Provider>
  );
};

export { MyContext, MyProvider };
