import React, { useRef, useEffect, useState } from 'react';
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
// import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import ProjectPropOne from '../component/project/itemProp/ProjectPropOne';
import { slugify } from '../utils';
import ServiceData from "../data/service/ServiceMain.json";
import ProjectData from "../data/project/ProjectData.json";
import AboutTwo from '../component/about/AboutTwo';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import Slider from 'react-slick';
import SEO from '../common/SEO';
import { Link } from 'react-router-dom';

const allServiceData = ServiceData;
const getProjectData = ProjectData;

const ServiceDetails = () => {
    const [isMuted] = useState(true); // State pour activer/désactiver le son des vidéos
    const videoRefs = useRef([]); // Ref pour stocker toutes les vidéos
    const params = useParams();
    const serviceSlug = params.slug;

    const getServiceData = allServiceData.filter(data => slugify(data.title) === serviceSlug);
    const detailsService = getServiceData[0];
    const tab = getServiceData[0]?.servDetail || {};
    const carouselServ = tab.large_thumb || [];
    const sideImg = tab.sideImg || [];

    const autoSelection = (carouselServ) => {
        if (serviceSlug === "animation") {
            return ["videos/Anim1.mp4", "videos/Anim2.mp4", "videos/Anim3.mp4", "videos/Anim4.mp4"];
        } else if (serviceSlug === "production-audiovisuelle") {
            return ["videos/AnimVidProd.mp4", "videos/AnimVidProd2.mp4"];
        } else {
            return carouselServ;
        }
    };

    useEffect(() => {
        const currentVideoRefs = videoRefs.current;
        const handlePlay = (entries) => {
            entries.forEach((entry) => {
                const videoElement = entry.target;
                if (entry.isIntersecting) {
                    videoElement.play().catch((error) => {
                        console.warn("Lecture automatique bloquée :", error);
                    });
                } else {
                    videoElement.pause();
                }
            });
        };

        const observer = new IntersectionObserver(handlePlay, { threshold: 0.5 });

        currentVideoRefs.forEach((videoRef) => {
            if (videoRef) {
                observer.observe(videoRef);
            }
        });

        return () => {
            currentVideoRefs.forEach((videoRef) => {
                if (videoRef) {
                    observer.unobserve(videoRef);
                }
            });
        };
    }, []);

    const SlickPrevArrow = ({ className, onClick }) => <div className={className} onClick={onClick}><FaAngleLeft /></div>;
    const SlickNextArrow = ({ className, onClick }) => <div className={className} onClick={onClick}><FaAngleRight /></div>;

    const slideSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SlickNextArrow />,
        prevArrow: <SlickPrevArrow />,
    };

    if (!detailsService) {
        return <div>Service non trouvé</div>;
    }

    return (
        <>
            <SEO title="Service Details" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerOne
                    title={detailsService.title}
                    paragraph={detailsService.description}
                    styleClass="rsp_img"
                    mainThumb={detailsService.servDetail?.imgBanner}
                />

                <div className="section section-padding pb--70">
                    <div className="container">
                        <div className="row">
                            <div className="single-blog">
                                <div className="row single-blog-content">
                                    <div className="post-thumbnail wid">
                                        <Slider {...slideSettings} className="slick-arrow-nav">
                                            {autoSelection(carouselServ).map((data, index) => (
                                                <div className="image-container rounded-5" key={index}>
                                                    {serviceSlug === "animation" || serviceSlug === "production-audiovisuelle" ? (
                                                        <video
                                                            className="rounded-5"
                                                            ref={(el) => (videoRefs.current[index] = el)}
                                                            width="100%"
                                                            muted={isMuted} // État dynamique pour activer/désactiver le son
                                                            preload="auto"
                                                            loop
                                                            playsInline
                                                            onError={(e) => console.error("Erreur vidéo :", e.target.src)}
                                                        >
                                                            <source src={`${process.env.PUBLIC_URL}/${data}`} type="video/mp4" />
                                                        </video>
                                                    ) : (
                                                        <img
                                                            className="img-fluid cs_radius_content"
                                                            src={`${process.env.PUBLIC_URL}/${data}`}
                                                            alt="Blog"
                                                        />
                                                    )}
                                                </div>
                                            ))}
                                        </Slider>
                                        <div className="mt-3 text-center">
                                        </div>
                                        <div className="mt-5">
                                            {tab.body?.map((htmlString, index) => (
                                                <div key={index} dangerouslySetInnerHTML={{ __html: htmlString }} />
                                            ))}
                                        </div>
                                    </div>
                                    <AboutTwo sideImg={sideImg} value={false} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section section-padding">
                    <div className="container">
                        <SectionTitle
                            subtitle="Autres projets"
                            title="Projets récents"
                            description=""
                            textAlignment=""
                            textColor=""
                        />
                        <div className="row row-35">
                            {getProjectData.slice(0, 2).map((data) => (
                                <div className="col-md-6" key={data.id}>
                                    <ProjectPropOne projectStyle="" portfolio={data} />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="more-project-btn">
                        <Link to={process.env.PUBLIC_URL + "/portfolio"} className="axil-btn btn-fill-white">Découvrir plus de projets</Link>
                    </div>
                </div>

                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    );
};

export default ServiceDetails;
