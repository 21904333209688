import React, {useContext} from 'react';
import { MyContext } from '../../utils/MyContext';
//import { FaLightbulb, FaMoon } from 'react-icons/fa';
import Lottie from "lottie-react";
import ligth from "../../assets/lotie/light-mode.json";
import dark from "../../assets/lotie/dark-mode.json";



const SwitcherHeader = () => {
    const {switchColor} = useContext(MyContext)
   

    return (
        
        <button onClick={switchColor}>
            <span className="setColor dark"><Lottie style={{ width: '60px', height: '60px', objectFit: 'contain' }} animationData={ligth} />   </span>
            <span className="setColor light"><Lottie style={{ width: '60px', height: '60px', objectFit: 'contain' }} animationData={dark} />   </span>
        </button>
       
    )
}

export default SwitcherHeader;