import React from 'react';
import FormOne from '../contact/FormOne';
import { Link } from 'react-router-dom';


const AboutTwo = ({ value, sideImg }) => {
    return (
        
        <div className="col">
            <div className="offset-xl-1">
                <div className="contact-form-box shadow-box mb--30">
                    <h3 className="title">Obtenez un devis dès maintenant</h3>
                    <FormOne />

                </div>
                {
                    !value?
                    <div className="cs_display widget widget-banner-ad">
                        <Link to="#">
                            <img src={process.env.PUBLIC_URL + sideImg} alt="banner" />
                        </Link>
                    </div>:""
                }
            </div>
        </div>
    )
}

export default AboutTwo;