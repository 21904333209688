import React from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerTwo from '../elements/breadcrumb/BcrumbBannerTwo';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import { slugify } from '../utils';
import ProjectData from "../data/project/ProjectData.json";
import AboutTwo from '../component/about/AboutTwo';
import VideoOne from '../component/video/VideoOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import { MdOutlineOpenInNew } from "react-icons/md";
import SEO from '../common/SEO';
const allProjectData = ProjectData;

const PortfolioDetails = () => {

    const params = useParams();
    const projectSlug = params.slug;

    const getProjectData = allProjectData.filter(data => slugify(data.title) === projectSlug);
    const detailsProject = getProjectData[0];


    return (
        <>
            <SEO title="Portfolio Details" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerTwo
                    title={detailsProject.title}
                    paragraph={detailsProject.excerpt}
                    mainThumb={detailsProject.image}
                />
                <section className="section-padding single-portfolio-area">
                    <div className="container">
                        <div className="cs_row_colomn">
                            <div className="">
                                <div className="section-heading heading-left mb-0">
                                    <span className="subtitle">
                                        {detailsProject.category.map((cat, i) => (
                                            // <span key={i}>{cat}</span>
                                            <Link className="link-primary" to={process.env.PUBLIC_URL + `/service-details/${slugify(cat)}`}  key={i} >{cat}</Link>
                                        )
                                        )}
                                    </span>
                                    <h4 className="title">{detailsProject.title}</h4>
                                </div>
                                {detailsProject.body.map((para, index) => (
                                    <p key={index} dangerouslySetInnerHTML={{ __html: para }}></p>
                                ))}                     
                                {detailsProject.visiteLink&&<a href={detailsProject.visiteLink} target='_blanck' className=" btn-fill-primary cs_btn_pdf"><MdOutlineOpenInNew /><span>Visiter</span></a>}
                            </div>

                            <div className="col-lg-5 offset-xl-1">
                                <AboutTwo value={true} />
                            </div>

                        </div>
                    </div>
                </section>
                <VideoOne
                    detailsProject={detailsProject}
                />
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default PortfolioDetails;