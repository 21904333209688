import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import BrandItem from './BrandItem';


const BrandOne = () => {
    return (
        <div className="section-pad bg-color-dark">
        <div className="container">
            <SectionTitle 
                // subtitle="Nos partenaires"
                title="Nos partenaires"
                // description="Ils s'engagent à offrir des solutions de qualité."
                textAlignment="heading-light-left"
                textColor=""
            />
            <div className='row brandContainer'>
               <BrandItem />
            </div>
        </div>
        <ul className="list-unstyled shape-group-10">
            <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/line-9.png"} alt="Circle" /></li>
        </ul>
    </div>
    )
}

export default BrandOne;