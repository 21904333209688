import React, {  useContext } from 'react';
import { MyContext } from '../../utils/MyContext';
//import { FaLightbulb, FaMoon } from 'react-icons/fa';
import Lottie from "lottie-react";
import ligth from "../../assets/lotie/light-mode.json";
import dark from "../../assets/lotie/dark-mode.json";



const ColorSwitcher = () => {
    const {switchColor} = useContext(MyContext)
     

    return (
        <div className="my_switcher d-none d-lg-block">
            <button onClick={switchColor}>
                <span className="setColor dark"><Lottie style={{ width: '60px', height: '60px' }}  animationData={ligth} />   </span>
                <span className="setColor light"><Lottie style={{ width: '60px', height: '60px' }}  animationData={dark} />   </span>
            </button>
        </div>
    )
}

export default ColorSwitcher;