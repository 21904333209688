import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import emailjs from '@emailjs/browser';




const FormOne = () => {

    const form = useRef();

    const location = useLocation();
    // const { closeModal } = useContext(MyContext)
    const [formData, setFormData] = useState({
        contact_name: '',
        contact_email: '',
        contact_message: '',
        contact_phone: '',
        link_page: `${location.pathname}`,
    });

    const [formErrors, setFormErrors] = useState({
        name: '',
        email: '',
        object: '',
        phone: '',
        objectEmpty: '',
        nmbChrt: '',
    });

    const emptyForm = ()=> {
        setFormData({
            contact_name: '',
            contact_email: '',
            contact_message: '',
            contact_phone: '',
            link_page: `${location.pathname}`,
        });
        setFormErrors({
            name: '',
            email: '',
            object: '',
            phone: '',
            objectEmpty: '',
            nmbChrt: '',
        });
    }

    const validateForm = () => {
        let errors = {};
        const nameRegex = /^[a-zA-Z\s]+$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phonRex = /^(?:\+?\d{1,3}[-.\s]?)?(?:\(?\d{3}\)?[-.\s]?)?\d{3}[-.\s]?\d{4}$/;


        if (!formData.contact_name.match(nameRegex)) {
            errors.name = 'Nom incorrect';
        }


        if (!formData.contact_email.match(emailRegex)) {
            errors.email = 'Adresse e-mail invalide';
        }

        if (!formData.contact_phone.match(phonRex)) {
            errors.phone = 'Veillez écrire un numero correct';
        }

        if (formData.contact_message === "") {
            errors.objectEmpty = 'Veillez écrire votre message';
        }
        if (formData.contact_message.length >= 250) {
            errors.nmbChrt = 'Veillez écrire moins 250 caractères';
        }
        if (formData.link_page === location.pathname) {
            // form.current.reset();
            emptyForm();
            // closeModal();
            
        }
        setFormErrors(errors);

        return Object.keys(errors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    function showAlert(icon, title, text) {
        Swal.fire({
            icon: icon,
            title: title,
            text: text,
            showConfirmButton: false,
            width: 500,
            timer: 3000,
            customClass: {
                content: 'custom-swal-container',
                popup: 'custom-swal-popup',
                htmlContainer: 'custom-swal-text',
            },
        });
    }

 

    const sendEmail = (e) => {
        e.preventDefault();
        const isValid = validateForm();
        if (isValid) {
        emailjs.sendForm('service_k07ceyf', 'template_2r5oiyd', form.current, 'p567uh0aN9_VACTUQ')
            .then((result) => {
                // console.log(result.text);
                emptyForm();
                showAlert('success', 'Message envoyé', 'Votre message a été envoyé avec succès.');
            }, (error) => {
                // console.log(error.text);
                showAlert('error', 'Erreur lors de l\'envoi', 'Une erreur est survenue lors de l\'envoi de votre message.');
                
            });
        // form.current.reset();
         }
      
    };



    return (
        <form ref={form} onSubmit={sendEmail} className="axil-contact-form">
            <div className="form-group">
                <label>Nom</label>
                <input value={formData.contact_name} onChange={handleChange}  type="text" className="form-control" name="contact_name" placeholder="Entrez votre nom" />
                <span className="error text-danger">{formErrors.name}</span>
            </div>
            <div className="form-group">
                <label>E-mail</label>
                <input value={formData.contact_email} onChange={handleChange} type="email" className="form-control" name="contact_email" placeholder="Entrez votre adresse mail" />
                <span className="error text-danger">{formErrors.email}</span>
            </div>
            <div className="form-group ">
                <label>Télephone</label>
                <input value={formData.contact_phone} onChange={handleChange} type="tel" className="form-control" name="contact_phone" placeholder="Entrez notre numéro" />
                <span className="error text-danger">{formErrors.phone}</span>
            </div>
            <div className="form-group mb--40">
                <label>Comment pouvons-nous vous aider?</label>
                <textarea value={formData.contact_message} onChange={handleChange} placeholder='Tapez votre message' name="contact_message" id="contact-message" className="form-control textarea" cols="30" rows="4"></textarea>
                <span className="error text-danger">{formErrors.nmbChrt}</span>
                <span className="error text-danger">{formErrors.objectEmpty}</span>
            </div>
            <input value={location.pathname} onChange={handleChange}  type="text" className="d-none" name="link_page" />
            <div className="form-group">
                <button type="submit" className="axil-btn btn-fill-primary btn-fluid btn-primary" name="submit-btn">Obtenir un devis</button>
            </div>
            
        </form>
    )
}

export default FormOne;