import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaYoutube, FaLinkedin, FaInstagram, FaEnvelopeOpen, FaTiktok } from "react-icons/fa";
import { FaXTwitter, FaThreads } from "react-icons/fa6";
import ServiceData from "../../data/service/ServiceMain.json";
import { slugify } from '../../utils';

const getServiceData = ServiceData;

const FooterOne = ({ parentClass }) => {

    return (
        <footer className={`footer-area ${parentClass}`}>
            <div className="container  cs_width_footer">
                <div className="footer-top">
                    <div className="footer-social-link">
                        <ul className="list-unstyled">
                            <li><a href="https://www.facebook.com/nzimgroup" target="blank" rel="noopener noreferrer"><FaFacebookF /></a></li>
                            <li><a href="https://x.com/NzimGroup" target="blank" rel="noopener noreferrer"><FaXTwitter /></a></li>
                            <li><a href="https://youtube.com/@nzimgroup?feature=shared" target="blank" rel="noopener noreferrer"><FaYoutube /></a></li>
                            <li><a href="https://www.linkedin.com/company/nzim-group/" target="blank" rel="noopener noreferrer"><FaLinkedin /></a></li>
                            <li><a href="https://www.instagram.com/nzim_group?igsh=MXFya3JkYmRzcGdqOA%3D%3D&utm_source=qr" target="blank" rel="noopener noreferrer"><FaInstagram /></a></li>
                            <li><a href="https://www.threads.net/@nzim_group" target="blank" rel="noopener noreferrer"><FaThreads /></a></li>
                            <li><a href="https://www.tiktok.com/@nzim_group?_t=8o2kpbqTHHh&_r=1" target="blank" rel="noopener noreferrer"><FaTiktok /></a></li>

                        </ul>
                    </div>
                </div>
                <div className="footer-main">
                    <div className="row">
                        <div className="col-xl-6 col-lg-5">
                            <div className="footer-widget border-end">
                                <div className="footer-newsletter">
                                    <h2 className="title">Newsletter!</h2>
                                    <p>Abonnez-vous à notre newsletter pour recevoir les dernières nouvelles.</p>
                                    <form>
                                        <div className="input-group">
                                            <span className="mail-icon"><FaEnvelopeOpen /> </span>
                                            <input type="email" className="form-control" placeholder="Adresse mail" />
                                            <button className="subscribe-btn" type="submit">S'abonner</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-7">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Services</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                {getServiceData.slice(0, 6).map((data, index) => (
                                                    <li key={index}>

                                                        <Link className="nav-link" to={process.env.PUBLIC_URL + `/services#${slugify(data.deadLink)}`}>{data.title}</Link>

                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Support</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li><Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link></li>
                                                <li> <Link to={process.env.PUBLIC_URL + "/politique-de-confidentialite"}>Politique de confidentialité</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + "/conditions-d-utilisation"}>Conditions d'utilisation</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Resourses</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li><Link to={process.env.PUBLIC_URL + "portfolio"}>Portfolio</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="footer-copyright">
                                <span className="copyright-text">© {new Date().getFullYear()} <a href="https://nzimgroup.com/">NZIM GROUP</a> Tous droits réservés.</span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="footer-bottom-link">
                                <ul className="list-unstyled">
                                    <li><Link to={process.env.PUBLIC_URL + "/politique-de-confidentialite"}>Politique de confidentialité</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/conditions-d-utilisation"}>Conditions d'utilisation</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterOne;