import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import ServiceProp from '../component/service/ServiceProp';
import ServiceData from "../data/service/ServiceMain.json";
import { slugify } from '../utils';
// import UpdateMetaTags from '../common/UpdateMetaTags';

const allData = ServiceData;

const Services = () => {
    const location = useLocation();


    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            setTimeout(() => {
                const element = document.getElementById(hash.replace(/#/g, ''));
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100);
        }
    }, [location]);

    const designData = allData.filter(data => slugify(data.cate ? data.cate : "") === "conception-digital");
    const developmentData = allData.filter(data => slugify(data.cate ? data.cate : "") === "design");
    const marketingData = allData.filter(data => slugify(data.cate ? data.cate : "") === "domotique");
    const businessData = allData.filter(data => slugify(data.cate ? data.cate : "") === "evenementiel");
    const technologyData = allData.filter(data => slugify(data.cate ? data.cate : "") === "service-informatique");
    const strategyData = allData.filter(data => slugify(data.cate ? data.cate : "") === "travaux-depannage");

    const pageTitle = "Services";


    return (
        <>

            <SEO title={pageTitle} />
            {/* <UpdateMetaTags
                title={pageTitle}
                description="This is a manual to updat OG tags kdkdmmepe emdmdpdmfoin Re  kovovorororro."
                imageUrl={`${window.location.origin}/images/banner/ServiceBan.png`}
            /> */}

            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerOne
                    title="Nos domaines d'expertise"
                    paragraph="Découvrez des services exceptionnels, conçus pour répondre à tous vos besoins avec efficacité et professionnalisme."
                    styleClass=""
                    mainThumb="/images/banner/ServiceBan.png"
                />
                <div className="service-scroll-navigation-area">
                    {/* Service Nav */}
                    <nav id="onepagenav" className="service-scroll-nav navbar onepagefixed">
                        <div className="container">
                            <ul className="nav nav-pills">
                                <li className="nav-item">
                                    <a className="nav-link" href="#section1">Conception Digitale</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#section2">Design Graphique</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#section3">Domotique</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#section4">Événementiel</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#section5">Service Informatique</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#section6">Travaux de Dépannage</a>
                                </li>
                            </ul>
                        </div>
                    </nav>

                    <div className="section section-padding" id="section1">
                        <div className="container">
                            <SectionTitle
                                subtitle="Service"
                                title="Conception Digitale"
                                description=""
                                textAlignment="heading-left"
                                textColor=""
                            />
                            <div className="row">
                                <ServiceProp colSize="col-lg-4 col-md-6" serviceStyle="service-style-2" serviceData={designData} />
                            </div>
                        </div>
                    </div>

                    <div className="section section-padding bg-color-light" id="section2">
                        <div className="container">
                            <SectionTitle
                                subtitle="Service"
                                title="Design Graphique"
                                description=""
                                textAlignment="heading-left"
                                textColor=""
                            />
                            <div className="row">
                                <ServiceProp colSize="col-lg-4 col-md-6" serviceStyle="service-style-2" serviceData={developmentData} />
                            </div>
                        </div>
                    </div>

                    <div className="section section-padding" id="section3">
                        <div className="container">
                            <SectionTitle
                                subtitle="Service"
                                title="Domotique"
                                description=""
                                textAlignment="heading-left"
                                textColor=""
                            />
                            <div className="row">
                                <ServiceProp colSize="col-lg-4 col-md-6" serviceStyle="service-style-2" serviceData={marketingData} />
                            </div>
                        </div>
                    </div>

                    <div className="section section-padding bg-color-light" id="section4">
                        <div className="container">
                            <SectionTitle
                                subtitle="Service"
                                title="Événementiel"
                                description=""
                                textAlignment="heading-left"
                                textColor=""
                            />
                            <div className="row">
                                <ServiceProp colSize="col-lg-4 col-md-6" serviceStyle="service-style-2" serviceData={businessData} />
                            </div>
                        </div>
                    </div>

                    <div className="section section-padding" id="section5">
                        <div className="container">
                            <SectionTitle
                                subtitle="Service"
                                title="Service Informatique"
                                description=""
                                textAlignment="heading-left"
                                textColor=""
                            />
                            <div className="row">
                                <ServiceProp colSize="col-lg-4 col-md-6" serviceStyle="service-style-2" serviceData={technologyData} />
                            </div>
                        </div>
                    </div>

                    <div className="section section-padding bg-color-light" id="section6">
                        <div className="container">
                            <SectionTitle
                                subtitle="Service"
                                title="Travaux de Dépannage"
                                description=""
                                textAlignment="heading-left"
                                textColor=""
                            />
                            <div className="row">
                                <ServiceProp colSize="col-lg-4 col-md-6" serviceStyle="service-style-2" serviceData={strategyData} />
                            </div>
                        </div>
                    </div>
                </div>
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    );
}

export default Services;
