import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import { Link } from 'react-router-dom';


const WhyChooseOne = () => {
    return (
        <div className="section-padding bg-color-light">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="why-choose-us">
                            <SectionTitle
                                subtitle="À propos"
                                title="Qui sommes-nous ?"
                                description="Nous sommes une entreprise multiservice innovante, dédiée à vous offrir la possibilité de bénéficier pleinement des avancées technologiques dans toutes les sphères de votre quotidien."
                                description2="Que ce soit pour améliorer votre productivité au travail ou simplifier votre vie à la maison, nous mettons à votre disposition une gamme complète de solutions adaptées à vos besoins."
                                textAlignment="heading-left"
                                textColor=""

                            />
                            <Link to={process.env.PUBLIC_URL + "/a-propos"} className="axil-btn btn-fill-primary btn-large">En savoir plus</Link>

                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6 offset-xl-1 p-4">
                        <div className=" mb--30">
                            <div className="cs_anim">
                                <img src={process.env.PUBLIC_URL + "/images/about/BanNzim.png"} alt="Office" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default WhyChooseOne;