import React, { useContext } from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { MyContext } from '../../utils/MyContext';



const BannerOne = () => {
    const { showModal } = useContext(MyContext);
    

    return (
        <div className="banner banner-style-1">
            <div className="container">
                <div className="row align-items-end align-items-xl-start">
                    <div className="col-lg-6">
                        <div className="banner-content">
                            <AnimationOnScroll animateIn="fadeInUp" animateOnce={true} delay={100}>
                                <h1 className="title">Boostez l’innovation en vous.</h1>
                                <span className="subtitle">Donnez vie à vos idées les plus lumineuses et favorisez l’innovation continue grâce à la technologie intelligente.</span>
                                {/* <a href="mailto:contact@nzimgroup.com" className="axil-btn btn-fill-primary btn-large">Contactez-nous</a> */}
                                <button onClick={showModal}  className="axil-btn btn-fill-primary btn-large cs_icon_text">Obtenir un devis</button>
                            </AnimationOnScroll>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="banner-thumbnail">
                            <AnimationOnScroll animateIn="zoomIn" duration={2} delay={300} animateOnce={true}>
                                <div className="large-thumb">
                                    <img src={process.env.PUBLIC_URL + "/images/banner/forme.png"} alt="Laptop" />
                                </div>
                            </AnimationOnScroll>
                            <AnimationOnScroll animateIn="slideInRight" duration={2} delay={300} animateOnce={true}>
                            {/* large-thumb-2 */}
                            <div >
                                <img className="img_computer" src={process.env.PUBLIC_URL + "/images/banner/man2.png"} alt="Laptop" />
                            </div>
                            </AnimationOnScroll>
                           
                        </div>
                    </div>
                </div>
            </div>
            <ul className="list-unstyled shape-group-21">
                <li className="shape shape-1">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-39.png"} alt="Bubble" />
                </li>
                <li className="shape shape-2">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-38.png"} alt="Bubble" />
                </li>
                <li className="shape shape-3">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
                </li>
                <li className="shape shape-4">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
                </li>
                <li className="shape shape-5">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
                </li>
                <li className="shape shape-6">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-40.png"} alt="Bubble" />
                </li>
                <li className="shape shape-7">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-41.png"} alt="Bubble" />
                </li>
            </ul>
        </div>
    )
}

export default BannerOne;