import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import AboutThree from '../component/about/AboutThree';
import AboutFour from '../component/about/AboutFour';


const Apropos = () => {


    return (
        <>
            <SEO title="À propos" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerOne
                    title="Qui sommes nous ?"
                    paragraph="Des solutions technologiques pour un travail plus productif et une vie plus simple."
                    styleClass="thumbnail-4"
                    mainThumb="/images/banner/teamWork.png"
                />
                <AboutFour />
                <AboutThree />
                <div className="section-padding-equal case-study-solution">
                    <div className="container">
                        <div className="aboutExpert">
                            <div className="thumbnail">
                                {
                                    <iframe
                                        className='vidImg  '
                                        src="https://www.youtube.com/embed/1rR1VmNMbfk"
                                        title="Logo NZIM GROUP"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerpolicy="strict-origin-when-cross-origin"
                                        allowfullscreen>
                                    </iframe>
                                }
                            </div>
                        </div>
                    </div>
                    <ul className="shape-group-10 list-unstyled">
                        <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="Line" /></li>
                        <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Line" /></li>
                    </ul>
                </div>
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default Apropos;