import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BannerOne from '../component/banner/BannerOne';
import BrandOne from '../component/brand/BrandOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ServicePropOne from '../component/service/ServicePropOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import VideoPub from '../component/video/VideoPub';
import ProjectFour from '../component/project/ProjectFour';
import CaseStudyProp from '../component/casestudy/CaseStudyProp';
import WhyChooseOne from '../component/whychoose/WhyChooseOne';




const Accueil = () => {


    return (
        <>

            <SEO title="NZIM GROUP" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BannerOne />
                <WhyChooseOne />
                <div className="section section-padding-2 bg-color-dark">
                    <div className="container">
                        <SectionTitle
                            subtitle="Nos services"
                            title="Nos domaines d'expertise"
                            description="Découvrez des services exceptionnels, conçus pour répondre à tous vos besoins avec efficacité et professionnalisme."
                            textAlignment="heading-light-left"
                            textColor=""
                        />
                        <div className='row'>
                            <ServicePropOne colSize="col-xl-4 col-md-6" serviceStyle="" itemShow="6" />
                        </div>
                    </div>
                    <ul className="list-unstyled shape-group-10">
                        <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/line-9.png"} alt="Circle" /></li>
                        <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-42.png"} alt="Circle" /></li>
                        <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-43.png"} alt="Circle" /></li>
                    </ul>
                </div>

                <div className="pt--130 pt_lg--200 pt_md--100 pt_sm--80 case-study-page-area ">
                    <div className="container">
                        <CaseStudyProp />
                    </div>
                </div>
                <ProjectFour />
                <VideoPub />
                <BrandOne />
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default Accueil;

