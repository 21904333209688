import React from 'react';

const Data = [
    {
        image: "/images/brand/BTKicon.png"
    },
    {
        image: "/images/brand/deliceAficainIcon.png"
    },
    {
        image: "/images/brand/le-congolais.png"
    },
    {
        image: "/images/brand/MasterPieceicon.png"
    },
    {
        image: "/images/brand/EagleGicon.png"
    },
    {
        image: "/images/brand/HekimaIcon.png"
    },
    {
        image: "/images/brand/VisitKasaiicon.png"
    },
    {
        image: "/images/brand/blessignMicon.png"
    }
]


const BrandItem = () => {
    return (
        <>
            {Data.map((data, index) => (
                <div className="col-lg-3 col-6" key={index}>
                    <div className="brand-grid ">
                        <img src={process.env.PUBLIC_URL + data.image} alt="Brand" />
                    </div>
                </div>
            ))}
        </>
    )
}

export default BrandItem;