import React from 'react';
// import SectionTitle from '../../elements/section-title/SectionTitle';

const Datas = [
    {
        id: 1,
        title: "Notre Mission",
        para: "Façonner les outils numériques pour optimiser la performance des entreprises en leur offrant la possibilité d'articuler précisément leurs besoins, afin de mieux répondre aux défis contemporains et de stimuler leur croissance durablement."
    },
    {
        id: 2,
        title: "Notre Vision ",
        para: "Nous visons à rendre votre entreprise à la hauteur de la compétition face à ses concurrents. L’excellence est une chose qui se taille tous les jours ; ainsi nous vous permettrons de vous faire une image forte auprès du public, et face à la concurrence. "
    },
    {
        id: 3,
        title: "Nos convictions",
        para: "Nous croyons fermement en la possibilité de créer des changements concrets et positifs par le biais de l'innovation et de l'action. Notre engagement absolu en faveur de l'égalité, de la confiance et de la protection des droits de tous nous anime au quotidien."
    },

]




const AboutThree = () => {
    return (
        <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
            <div className="container">
               

                <div className="row">
                    {Datas.map((data) => (
                        <div className="col-lg-4" key={data.id}>
                            <div className="about-quality">
                                <h3 className="sl-number">{data.id}</h3>
                                <h5 className="title">{data.title}</h5>
                                <p>{data.para}</p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="Circle" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Circle" /></li>
            </ul>
        </div>
    )
}

export default AboutThree;