import React, { useState, useEffect } from 'react';
import ProjectPropOne from './itemProp/ProjectPropOne';
import ProjectData from "../../data/project/ProjectData.json";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import ReactPaginate from 'react-paginate';
import { useSearchParams } from "react-router-dom";

const AllData = ProjectData.reverse();

const ProjectFive = ({ colSize, parentClass, perPageShow }) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const initialPage = parseInt(searchParams.get("page")) || 0;
    const [projects] = useState(AllData.slice(0, 108));
    const [pageNumber, setPageNumber] = useState(initialPage);

    const scrollToTop = () => {
        setTimeout(() => {
            const element = document.getElementById('prtfCont');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    };

    const projectPerPage = perPageShow ? perPageShow : 6;
    const pageVisited = pageNumber * projectPerPage;

    const displayProjects = projects.slice(pageVisited, pageVisited + projectPerPage)
        .map((data) => (
            <div className="col" key={data.id}>
                <ProjectPropOne projectStyle="" portfolio={data} />
            </div>
        ));

    const pageCount = Math.ceil(projects.length / projectPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
        setSearchParams({ page: selected });
        scrollToTop();
    };


    useEffect(() => {
        const urlPage = parseInt(searchParams.get("page")) || 0;
        if (urlPage !== pageNumber) {
            setPageNumber(urlPage);
        }
    }, [searchParams, pageNumber]);

    return (
        <div id='prtfCont' className={`section section-padding-equal pt--200 pt_md--80 pt_sm--60 ${parentClass ? parentClass : ""}`}>
            <div className="container-fluid plr--30">
                <div className={`row row-cols-1 ${colSize ? colSize : "row-cols-sm-2 row-cols-xl-3"}`}>
                    {displayProjects}
                </div>
                <ReactPaginate
                    previousLabel={<FaArrowLeft />}
                    nextLabel={<FaArrowRight />}
                    pageCount={pageCount}
                    forcePage={pageNumber}
                    onPageChange={changePage}
                    containerClassName={"pagination"}
                    previousLinkClassName={"prev"}
                    nextLinkClassName={"next"}
                    disabledClassName={"disabled"}
                    activeClassName={"current"}
                />
            </div>
        </div>
    );
};

export default ProjectFive;
