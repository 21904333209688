import React from 'react';
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import Slider from "react-slick";
// import FsLightbox from 'fslightbox-react';


const VideoOne = ({ detailsProject }) => {

    // const [toggler, setToggler] = useState(false);
    const dataSlide = detailsProject.dataSlide

    
    var slideSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SlickNextArrow />,
        prevArrow: <SlickPrevArrow />,

    }
    function SlickNextArrow(props) {
        const { className, onClick } = props;
        return (
            <div className={className} onClick={onClick}><FaAngleRight /></div>
        );
    }

    function SlickPrevArrow(props) {
        const { className, onClick } = props;
        return (
            <div className={className} onClick={onClick}><FaAngleLeft /></div>
        );
    }

    return (
        <>
            <div className="section section-padding-equal bg-color-light">
                <div className="container">
                    {
                        detailsProject.vidlink ?
                            // <div className="about-expert">

                            //     <div className="thumbnail">
                            //         <img className='cs_radius_content' src={process.env.PUBLIC_URL + "/images/about/about-1.png"} alt="Thumbnail" />
                            //         <div className="popup-video">
                            //             <button className="play-btn" onClick={() => setToggler(!toggler)}><FaPlay /></button>
                            //         </div>
                            //     </div>

                            // </div> 
                            <div className="aboutExpert ">
                                <div className="thumbnail">
                                    <iframe className='vidImg' src={detailsProject.vidlink} title={detailsProject.vidTitle} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                            </div>
                            :
                            <div>
                                {
                                    Array.isArray(dataSlide)&&
                                        <Slider {...slideSettings} className="slick-arrow-nav css_img_prjt_dtl ">
                                            {dataSlide.map((data, index) => (
                                                <div className="" key={index}>
                                                    <img className='img-fluid cs_radius_content' src={data} alt="Blog" />
                                                </div>
                                            ))}
                                        </Slider>
                                       
                                }
                            </div>
                    }
                </div>
                <ul className="shape-group-16 list-unstyled">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-2.png"} alt="circle" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="Line" /></li>
                    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Line" /></li>
                </ul>
            </div>
            {/* <FsLightbox toggler={toggler} sources={detailsProject.vidlink} /> */}
        </>
    )
}


export default VideoOne;